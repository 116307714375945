/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import { cleanPropsWithRelations } from '@/utils'
import useConstant from '@/hooks/useConstant'
import * as T from '@/types'

function UseCaseIndex({
	pageContext: { media, wordpress_id },
	data: {
		posts: { nodes: posts },
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() =>
		cleanPropsWithRelations(
			blocks,
			media,
			{
				'acf/case-study-card-slider': {
					data: [...posts],
					key: 'case_studies'
				}
			}
		)
    )

	const topItems = data.filter(
		item =>
			item.type !== 'acf/form-block' &&
			item.type !== 'acf/case-study-card-slider'
	)

	const slide = data.find(item => item.type === 'acf/case-study-card-slider')
	const form = data.find(item => item.type === 'acf/form-block')

	const list = [...topItems, slide, form]

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={list} media={media} currentPage={wordpress_id} />
		</>
	)
}

UseCaseIndex.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired,
		posts: T.posts.isRequired
	})
}

export const query = graphql`
	query UseCaseIndex($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}

        posts: allWordpressPost(filter: { slug: { ne: "template" } }) {
			nodes {
				...postThumbQuery
			}
        }
	}
`

export default UseCaseIndex
